<!-- DownloadView.vue -->
<template>
    <div style="width: 100%;padding: 0px 18px; background: linear-gradient(
            180deg,#fff,rgba(241,239,255,.49125) 53.12%,rgba(220,213,255,.262778) 80.73%,rgba(127,102,255,.08));">
        <div
            style="max-width: 1200px; width: 100%;margin: auto;font-size: 32px;height: 80px;text-align: left;line-height: 80px;padding-left: 30px;font-weight: bold;">
            下载
        </div>
    </div>
    <div style="max-width: 1200px; width: 100%;margin: auto;text-align: left;display: flex;">
        <div style="width: 220px;min-height: 800px;display: inline-block;">
            <a-menu v-model:selectedKeys="selectedKeys" theme="light" mode="inline" style="min-height: 800px;">
                <a-menu-item key="/">
                    <pie-chart-outlined />
                    <span>通用软件</span>
                </a-menu-item>
                <a-menu-item key="/tool">
                    <pie-chart-outlined />
                    <span>快速工具</span>
                </a-menu-item>
            </a-menu>
        </div>
        <div style="flex-grow: 1;min-height: 800px;display: inline-block;padding-top: 10px;padding-left: 30px;">
            <div style="font-size: 32px;font-weight: 500;font-family: cursive;">通用软件</div>
            <div
                style="display: flex;border: 1px gray solid;padding: 0px;border-radius: 10px;margin-top: 20px;background-color: rgb(240, 240, 240);">
                <div style="display: inline-block;width: 100px;height: 100px;">
                    <img src="../assets/logo.png"
                        style="width: 100px;border-top-left-radius: 10px;border-bottom-left-radius: 10px;" />
                </div>
                <div style="flex-grow: 1;padding: 10px;padding-left: 15px;">
                    <div style="font-size: 18px;font-weight: bold;">
                        AiSight 1.0.3.2
                    </div>
                    <div style="font-size: 16px;margin-top: 7px;">
                        集成了所有模块的通用机器视觉软件
                    </div>
                    <div style="font-size: 16px;margin-top: 6px;">
                        [上次更新时间] 2025-03-17
                    </div>
                </div>
                <div style="display: inline-block;width: 100px;text-align: center;align-self: center;">
                    <a-button @click="download('./download/AiSight_1.0.3.2.zip')">
                        <template #icon>
                            <DownloadOutlined />
                        </template>下载</a-button>
                </div>
            </div>
            <div style="font-size: 32px;font-weight: 500;font-family: cursive;margin-top: 32px;">快速工具</div>
            <div
                style="display: flex;border: 1px gray solid;padding: 0px;border-radius: 10px;margin-top: 20px;background-color: rgb(240, 240, 240);">
                <div style="display: inline-block;width: 100px;height: 100px;">
                    <img src="../assets/quickcircle.png"
                        style="width: 100px;border-top-left-radius: 10px;border-bottom-left-radius: 10px;" />
                </div>
                <div style="flex-grow: 1;padding: 10px;padding-left: 15px;">
                    <div style="font-size: 18px;font-weight: bold;">
                        快速圆检测工具
                    </div>
                    <div style="font-size: 16px;margin-top: 7px;">
                        自动检测图片中圆形产品的大小和位置。
                    </div>
                    <div style="font-size: 16px;margin-top: 6px;">
                        [上次更新时间] 2025-03-17
                    </div>
                </div>
                <div style="display: inline-block;width: 100px;text-align: center;align-self: center;">
                    <a-button @click="download('./download/快速圆检测_1.0.3.2.zip')">
                        <template #icon>
                            <DownloadOutlined />
                        </template>下载</a-button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import { DownloadOutlined } from '@ant-design/icons-vue';

export default {
    name: 'DownloadView',
    components: {
        DownloadOutlined,
    },
    data() {
        return {
        }
    },
    methods: {
        download(path) {
            window.open(path, '_blank');
        }
    }
}
</script>
<style scoped></style>