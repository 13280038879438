<!-- ProjectView.vue -->
<template>
    <div style="width: 100%;padding: 0px 18px; background: linear-gradient(
            180deg,#fff,rgba(241,239,255,.49125) 53.12%,rgba(220,213,255,.262778) 80.73%,rgba(127,102,255,.08));">
        <div
            style="max-width: 1200px; width: 100%;margin: auto;font-size: 32px;height: 80px;text-align: left;line-height: 80px;padding-left: 30px;">
            案例
        </div>
    </div>
    <div style="max-width: 1200px; width: 100%;margin: auto;text-align: left;display: flex;">
        <div style="width: 220px;min-height: 800px;display: inline-block;">

            <a-menu v-model:selectedKeys="selectedKeys" theme="light" mode="inline" style="min-height: 800px;">
                <a-menu-item key="/">
                    <span>定位</span>
                </a-menu-item>
                <a-menu-item key="/camera">
                    <span>测量</span>
                </a-menu-item>
                <a-menu-item key="/tool">
                    <span>识别</span>
                </a-menu-item>
                <a-menu-item key="/communication">
                    <span>检测</span>
                </a-menu-item>
            </a-menu>
        </div>
        
        <div style="flex-grow: 1;min-height: 800px;display: inline-block;padding-top: 10px;padding-left: 30px;">
            <div style="font-size: 32px;font-weight: 500;font-family: cursive;">定位</div>
            <div style="font-size: 32px;font-weight: 500;font-family: cursive;margin-top: 32px;">测量</div>
            <div style="font-size: 32px;font-weight: 500;font-family: cursive;margin-top: 32px;">识别</div>
            <div style="font-size: 32px;font-weight: 500;font-family: cursive;margin-top: 32px;">检测</div>
        </div>
    </div>
</template>

<script>

export default {
    name: 'ProjectView',
    components: {
    },
    data() {
        return {
        }
    }
}
</script>
<style scoped></style>