<template>
  <a-layout>
    <a-layout-header :style="headerStyle">
      <HeaderCard />
    </a-layout-header>
    <a-layout-content :style="contentStyle">
      <router-view></router-view>
    </a-layout-content>
    <a-layout-footer style="background-color: white;color: black;">
      <a href="http://beian.miit.gov.cn" target="_blank">
        <div style="background-color: white;color: black;">备案号：
          浙ICP备2024134642号-2</div>
      </a>
    </a-layout-footer>
  </a-layout>
</template>

<script>
import HeaderCard from './components/HeaderCard.vue';

const headerStyle = {
  position: 'fixed',
  zIndex: 1,
  width: '100%',
  textAlign: 'center',
  color: '#464646',
  height: 64,
  paddingInline: '0px',
  lineHeight: '64px',
  backgroundColor: '#fff',
};
const contentStyle = {
  textAlign: 'center',
  backgroundColor: '#fff',
  marginTop: '64px',
  // display: 'flex',
  // justifyContent: 'center', /* 水平居中 */
  // alignItems: 'center'     /* 垂直居中 */
};

const footerStyle = {
  textAlign: 'center',
  color: '#fff',
};

export default {
  name: 'App',
  components: {
    HeaderCard
  },
  data() {
    return {
      headerStyle,
      contentStyle,
      footerStyle
    }
  },

}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  font-family: "PingFang SC", "Microsoft YaHei", "Helvetica Neue", Helvetica, Arial, sans-serif;

}

h2 {
  font-family: "PingFang SC", "Microsoft YaHei", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 48px;
}
</style>
