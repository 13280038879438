<template>
    <div style="width: 100%;padding: 0px 18px; ">
        <div style="max-width: 1200px; width: 100%;margin: auto;">


        </div>
        <div style="max-width: 1200px; width: 100%;margin: auto;">
            <a-row :gutter="16" justify="center" align="middle"
                style="padding-top: 30px;padding-bottom: 30px;padding-left: 24px;padding-right: 24px;">
                <a-col style="max-width: 550px; padding-right: 28px;" :span="4" :xs="24" :sm="24" :md="11" :lg="11"
                    :xl="11" :xxl="11">
                    <div style="padding-top: 18px;">
                        <h3 style="font-size: 34px;
    font-weight: 500;
    line-height: 1.5;
    margin-bottom: 0;
    text-align: left;">快速 <mark style="padding: 0;
    color: #7f66ff;
    background-color: transparent; white-space: nowrap;">圆检测</mark> 便携工具</h3>
                        <p
                            style="margin: 0;text-align: left;max-width: 460px;line-height: 2rem;color: rgba(25, 20, 51, 0.8);font-weight: 400; font-size: 1.125rem;margin-top:30px; margin-bottom:32px">
                            从图片中自动定位圆形产品的位置，并根据边缘拟合圆。输出圆的大小和位置。
                        </p>
                    </div>
                </a-col>
                <a-col :span="20" :xs="24" :sm="24" :md="13" :lg="13" :xl="13" :xxl="13" style="max-width: 650px;">
                    <div style=" width: 86%;margin-left: 12%;border-radius: 6px;">
                        <img src="../assets/quickcircle.png"
                            style="width: 100%;vertical-align:top;border-radius: 6px;" />
                    </div>
                </a-col>
            </a-row>
        </div>
    </div>
</template>

<script>


export default {
    name: 'HomeQuickCircleCard',
    components: {
    }
}
</script>
<style></style>