<!-- Home.vue -->
<template>

    <a-row :gutter="16" justify="center" style="padding-left: 24px;padding-right: 24px;">
        <a-col :style="screens.lg ? { maxWidth: '200px' } : {}" :span="6" :xs="9" :sm="9" :md="6" :lg="4" :xl="4"
            :xxl="4">
            <h1 style="margin: 0;text-align: left;">
                <img alt="logo" style="height: 32px; position: relative;
    top: -1.5px;margin-right: 10px;vertical-align: middle;border-style: none;" src="../assets/logo.png" />
                AiSight
            </h1>
        </a-col>
        <a-col :style="screens.lg ? { maxWidth: '1000px' } : {}" :span="18" :xs="15" :sm="15" :md="18" :lg="20" :xl="20"
            :xxl="20">
            <a-menu v-model:selectedKeys="current" mode="horizontal">
                <a-menu-item key="/" v-on:click="goto('/')">
                    <span>主页</span>
                </a-menu-item>
                <a-menu-item key="document" v-on:click="goto('/document')">
                    <span>资料</span>
                </a-menu-item>
                <a-menu-item key="download" v-on:click="goto('/download')">
                    <span>下载</span>
                </a-menu-item>
            </a-menu>
        </a-col>
    </a-row>

</template>

<script>

import { Grid } from 'ant-design-vue';

const useBreakpoint = Grid.useBreakpoint;
export default {
    name: 'HeaderCard',
    components: {

    },
    data() {
        var screens = useBreakpoint();
        return {
            current: [this.$route.path],
            screens: screens,
        }
    },
    methods: {
        goto(path) {
            this.$router.push(path);
        },
        openManual() {
            window.open('./download/AiSight算法平台用户手册.pdf', '_blank');
        },
        openDownload(){
            window.open('./download/AiSight1.0.3.1.zip', '_blank');
        }
    }
}
</script>