import { createRouter, createWebHistory } from 'vue-router';
import HomeView from '../views/HomeView.vue';
import TutorialView from '../views/TutorialView.vue';
import ProjectView from '../views/ProjectView.vue';
import DownloadView from '../views/DownloadView.vue';

const routes = [
    {
        path: '/',
        name: 'home',
        component: HomeView,
    },
    {
        path: '/document',
        name: 'document',
        component: TutorialView
    },
    {
        path: '/project',
        name: 'project',
        component: ProjectView
    },

    {
        path: '/download',
        name: 'download',
        component: DownloadView
    },

    // ... 其他路由
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});

export default router;
