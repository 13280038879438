<!-- TutorialView.vue -->
<template>
    <div style="width: 100%;padding: 0px 18px; background: linear-gradient(
            180deg,#fff,rgba(241,239,255,.49125) 53.12%,rgba(220,213,255,.262778) 80.73%,rgba(127,102,255,.08));">
        <div
            style="max-width: 1200px; width: 100%;margin: auto;font-size: 32px;height: 80px;text-align: left;line-height: 80px;padding-left: 30px;font-weight: bold;">
            资料
        </div>
    </div>
    <div style="max-width: 1200px; width: 100%;margin: auto;text-align: left;display: flex;">
        <div style="width: 220px;min-height: 800px;display: inline-block;">
            <a-menu v-model:selectedKeys="selectedKeys" theme="light" mode="inline" style="min-height: 800px;">
                <a-menu-item key="/">
                    <pie-chart-outlined />
                    <span>用户手册</span>
                </a-menu-item>
                <a-menu-item key="/video">
                    <pie-chart-outlined />
                    <span>视频教程</span>
                </a-menu-item>
                <a-menu-item key="/tool">
                    <pie-chart-outlined />
                    <span>实用工具</span>
                </a-menu-item>
                <a-menu-item key="/communication">
                    <pie-chart-outlined />
                    <span>通信</span>
                </a-menu-item>
            </a-menu>
        </div>
        <div style="flex-grow: 1;min-height: 800px;display: inline-block;padding-top: 10px;padding-left: 30px;">
            <div style="font-size: 32px;font-weight: 500;font-family: cursive;">用户手册</div>
            <div
                style="display: flex;border: 1px gray solid;padding: 0px;border-radius: 10px;margin-top: 20px;background-color: rgb(240, 240, 240);">
                <div style="display: inline-block;width: 100px;height: 100px;">
                    <img src="../assets/manual.png"
                        style="width: 100px;border-top-left-radius: 10px;border-bottom-left-radius: 10px;" />
                </div>
                <div style="flex-grow: 1;padding: 10px;padding-left: 15px;">
                    <div style="font-size: 18px;font-weight: bold;">
                        AiSight算法平台用户手册.pdf
                    </div>
                    <div style="font-size: 16px;margin-top: 7px;">
                        使用AiSight时的必读操作手册
                    </div>
                    <div style="font-size: 16px;margin-top: 6px;">
                        [上次更新时间] 2025-03-01
                    </div>
                </div>
                <div style="display: inline-block;width: 100px;text-align: center;align-self: center;">
                    <a-button @click="download('./download/AiSight算法平台用户手册.pdf')">
                        <template #icon>
                            <DownloadOutlined />
                        </template>下载</a-button>
                </div>
            </div>
            <div style="font-size: 32px;font-weight: 500;font-family: cursive;margin-top: 32px;">视频教程</div>
            <div
                style="display: flex;border: 1px gray solid;padding: 0px;border-radius: 10px;margin-top: 20px;background-color: rgb(240, 240, 240);">
                <div style="display: inline-block;width: 100px;height: 100px;">
                    <img src="../assets/CameraSetting.jpg"
                        style="width: 100px;border-top-left-radius: 10px;border-bottom-left-radius: 10px;" />
                </div>
                <div style="flex-grow: 1;padding: 10px;padding-left: 15px;">
                    <div style="font-size: 18px;font-weight: bold;">
                        AiSight基本操作
                    </div>
                    <div style="font-size: 16px;margin-top: 7px;">
                        拍摄设定与图片保存
                    </div>
                </div>
                <div style="display: inline-block;width: 100px;text-align: center;align-self: center;">
                    <a-button @click="download('./video/CameraSetting.mp4')">
                        <template #icon>
                            <PlayCircleOutlined />
                        </template>播放</a-button>
                </div>
            </div>
            <div
                style="display: flex;border: 1px gray solid;padding: 0px;border-radius: 10px;margin-top: 20px;background-color: rgb(240, 240, 240);">
                <div style="display: inline-block;width: 100px;height: 100px;">
                    <img src="../assets/RegionSetting.jpg"
                        style="width: 100px;border-top-left-radius: 10px;border-bottom-left-radius: 10px;" />
                </div>
                <div style="flex-grow: 1;padding: 10px;padding-left: 15px;">
                    <div style="font-size: 18px;font-weight: bold;">
                        AiSight基本操作
                    </div>
                    <div style="font-size: 16px;margin-top: 7px;">
                        检测范围与补正源
                    </div>
                </div>
                <div style="display: inline-block;width: 100px;text-align: center;align-self: center;">
                    <a-button @click="download('./video/RegionSetting.mp4')">
                        <template #icon>
                            <PlayCircleOutlined />
                        </template>播放</a-button>
                </div>
            </div>
            <div
                style="display: flex;border: 1px gray solid;padding: 0px;border-radius: 10px;margin-top: 20px;background-color: rgb(240, 240, 240);">
                <div style="display: inline-block;width: 100px;height: 100px;">
                    <img src="../assets/HistoryImage.jpg"
                        style="width: 100px;border-top-left-radius: 10px;border-bottom-left-radius: 10px;" />
                </div>
                <div style="flex-grow: 1;padding: 10px;padding-left: 15px;">
                    <div style="font-size: 18px;font-weight: bold;">
                        AiSight基本操作
                    </div>
                    <div style="font-size: 16px;margin-top: 7px;">
                        图像管理
                    </div>
                </div>
                <div style="display: inline-block;width: 100px;text-align: center;align-self: center;">
                    <a-button @click="download('./video/HistoryImage.mp4')">
                        <template #icon>
                            <PlayCircleOutlined />
                        </template>播放</a-button>
                </div>
            </div>
            <div
                style="display: flex;border: 1px gray solid;padding: 0px;border-radius: 10px;margin-top: 20px;background-color: rgb(240, 240, 240);">
                <div style="display: inline-block;width: 100px;height: 100px;">
                    <img src="../assets/FlowSetting.jpg"
                        style="width: 100px;border-top-left-radius: 10px;border-bottom-left-radius: 10px;" />
                </div>
                <div style="flex-grow: 1;padding: 10px;padding-left: 15px;">
                    <div style="font-size: 18px;font-weight: bold;">
                        AiSight基本操作
                    </div>
                    <div style="font-size: 16px;margin-top: 7px;">
                        流程管理
                    </div>
                </div>
                <div style="display: inline-block;width: 100px;text-align: center;align-self: center;">
                    <a-button @click="download('./video/FlowSetting.mp4')">
                        <template #icon>
                            <PlayCircleOutlined />
                        </template>播放</a-button>
                </div>
            </div>
            <div style="font-size: 32px;font-weight: 500;font-family: cursive;margin-top: 32px;">实用工具</div>
            <div style="font-size: 32px;font-weight: 500;font-family: cursive;margin-top: 32px;">通信</div>
        </div>
    </div>
</template>

<script>

import { DownloadOutlined, PlayCircleOutlined } from '@ant-design/icons-vue';

export default {
    name: 'TutorialView',
    components: {
        DownloadOutlined,
        PlayCircleOutlined,
    },
    data() {
        return {
        }
    },
    methods: {
        download(path) {
            window.open(path, '_blank');
        }
    }
}
</script>
<style scoped></style>